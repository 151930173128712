import React, { useEffect } from "react"
import { appWithTranslation } from "next-i18next"
import NextNprogress from "nextjs-progressbar"
import "../styles/globals.css"
import Script from "next/script"

const App = ({ Component, pageProps }) => {
  return (
    <>
      <NextNprogress
        color="rgba(219, 39, 119)"
        startPosition={0.3}
        stopDelayMs={200}
        height={3}
        showOnShallow={true}
      />
      <Component {...pageProps} />
      <Script src="https://r.wdfl.co/rw.js" data-rewardful="f7a67e"></Script>
      <Script id="rewardful-queue" strategy="beforeInteractive">
        {`(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`}
      </Script>
    </>
  )
}

export default appWithTranslation(App)
